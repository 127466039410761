<!-- 耕雲導師詩歌及語法 -->
<template>
  <!-- 耕雲導師讲词 -->
  <div class="word">
    <div v-for="(item, index) in contentList" :key="index" style="width: 100%">
      <table border="1" cellspacing="0">
        <tr class="ta-l">
          <th colspan="6">{{ item.cate_name }}</th>
        </tr>
        <tr
          v-show="item.lists && item.lists[0].audio_title"
          v-for="(item1, index1) in getLength(item.lists)"
          :key="index1"
        >
          <td class="numTitle">{{ index1 + 1 }}</td>
          <td
            class="leftTitle contentTitle ta-l"
            @click="linkToDetail(item.lists[index1])"
          >
            {{ item.lists[index1].audio_title }}
          </td>
          <td
            class="music"
            @click="linkToSongDetail(contentList[index].lists[index1])"
          >
            <div v-if="item.lists[index1].audio_url">{{ $t("song") }}</div>
          </td>
          <td class="numTitle" v-show="index1 + getLength(item.lists) + 1">
            <div v-if="item.lists[index1 + getLength(item.lists)]">
              {{ index1 + getLength(item.lists) + 1 }}
            </div>
          </td>
          <td class="ta-l pl5">
            <div
              @click="linkToDetail(item.lists[index1 + getLength(item.lists)])"
              class="contentTitle"
              v-if="item.lists[index1 + getLength(item.lists)]"
            >
              {{ item.lists[index1 + getLength(item.lists)].audio_title }}
            </div>
          </td>
          <td
            class="music"
            @click="
              linkToSongDetail(
                item.lists.length % 2 == 0
                  ? contentList[index].lists[index1 + item.lists.length / 2]
                  : contentList[index].lists[
                      index1 + item.lists.length / 2 + 0.5
                    ]
              )
            "
          >
            <div
              v-if="
                item.lists[index1 + getLength(item.lists)] &&
                item.lists[index1 + getLength(item.lists)].audio_url
              "
            >
              {{ $t("song") }}
            </div>
          </td>
        </tr>
      </table>
    </div>
    <!--        <div v-for="(item,index) in length" :key="index" style="width:100%;">-->
    <!--            <table border="1" cellspacing="0">-->
    <!--                <tr class="ta-l">-->
    <!--                    <th colspan="3" style="width:50%">{{contentList[2*index].cate_name}}</th>-->
    <!--                    <th colspan="3" style="width:50%">{{contentList[2*index+1].cate_name}}</th>-->
    <!--                </tr>-->
    <!--                &lt;!&ndash; <tr class="ta-l">-->
    <!--                    <th colspan="3">安祥禪第一集</th>-->
    <!--                    <th colspan="3">安祥禪第二集</th>-->
    <!--                </tr>&ndash;&gt;-->

    <!--                <tr v-for="(item1,index1) in contentList[2*index].lists.length" :key="index1">-->
    <!--                    <td class="numTitle">-->
    <!--                        <div v-if="contentList[2*index].lists[index1]">{{index1+1}}</div>-->
    <!--                    </td>-->
    <!--                    <td-->
    <!--                            class="contentTitle"-->
    <!--                            @click="linkToDetail(contentList[2*index].lists[index1])"-->
    <!--                    >-->
    <!--                        <div-->
    <!--                                v-if="contentList[2*index].lists[index1]"-->
    <!--                        >{{contentList[2*index].lists[index1].audio_title}}-->
    <!--                        </div>-->
    <!--                    </td>-->
    <!--                    <td class="music" @click="linkToSongDetail(contentList[2*index].lists[index1])">-->
    <!--                        <div v-if="contentList[2*index].lists[index1]">{{$t('song')}}</div>-->
    <!--                    </td>-->
    <!--                    <td class="numTitle">-->
    <!--                        <div v-if="contentList[2*index+1].lists[index1]">{{index1+1}}</div>-->
    <!--                    </td>-->
    <!--                    <td-->
    <!--                            class="contentTitle"-->
    <!--                            @click="linkToDetail(contentList[2*index+1].lists[index1])"-->
    <!--                    >-->
    <!--                        <div-->
    <!--                                v-if="contentList[2*index+1].lists[index1]"-->
    <!--                        >{{contentList[2*index+1].lists[index1].audio_title}}-->
    <!--                        </div>-->
    <!--                    </td>-->
    <!--                    <td-->
    <!--                            class="music"-->
    <!--                            @click="linkToSongDetail(contentList[2*index+1].lists[index1])"-->
    <!--                    >-->
    <!--                        <div v-if="contentList[2*index+1].lists[index1]">{{$t('song')}}</div>-->
    <!--                    </td>-->
    <!--                </tr>-->
    <!--            </table>-->
    <!--        </div>-->

    <div class="line"></div>
    <p class="txt_zhus">注：带有“*”号的，均配有　耕云导师墨宝。</p>
  </div>
</template>

<script>
import { getCateList, getPoetry } from "../../api/apis";

export default {
  name: "",
  components: {},

  data() {
    return {
      contentList: [],
      length: 0,
    };
  },

  methods: {
    // 获取子列表lists的长度,向上取整
    getLength(arr) {
      let length = arr.length;
      this.length = Math.ceil(length / 2);
      return Math.ceil(length / 2);
    },
    //文章详情
    linkToDetail(item) {
      window.open(this.$BaseURL + "/articleDetail?name=poetry&id=" + item.id);
      // this.$router.push({
      //     path: "/articleDetail",
      //     query: { name: "poetry", id: item.id }
      // });
    },
    //歌曲详情
    linkToSongDetail(item) {
      if (item.new_link) {
        // window.open(
        //   this.$BaseURL + "/newPage/newPage?url=" + item.new_link,
        //   "_self"
        // );
        window.open(item.new_link)
      } else {
        window.open(this.$BaseURL + "/musicDetail?name=poetry&id=" + item.id);
      }
      // this.$router.push({
      //     path: "/musicDetail",
      //     query: { name: "poetry", id: item.id }
      // });
    },
    //初始化数据
    initData() {
      let list = this.contentList;
      this.length = Math.ceil(list.length / 2);

      //处理基数条数据的时候
      if (list.length % 2 === 1) {
        list[list.length] = { lists: [] };
        list[list.length].lists.length = list[list.length - 1].lists.length;
      }
      //处理基偶条数据时，比较数据，采用最长的数据条数
      for (let i = 0; i < this.length; i++) {
        if (list[2 * i].lists.length > list[2 * i + 1].lists.length) {
          list[2 * i + 1].lists.length = list[2 * i].lists.length;
        } else {
          list[2 * i].lists.length = list[2 * i + 1].lists.length;
        }
      }
    },
  },
  created() {
    // this.initData();
    getCateList().then((res) => {
      let id = res[1][3].id;
      getPoetry(id).then((res) => {
        this.contentList = res;
        this.initData();
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
.ta-l {
  text-align: left;
}

.pl5 {
  padding-left: 5px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ACACAC;
}

.word {
  width: 750px;
  margin: 20px 10px;
  

  .title {
    font-size: 15px;
    font-weight: 600;
  }
}

table, td, tr, th {
  border: 1px solid #ACACAC;
  border-bottom: 0px solid #ACACAC;
}

table {
  width: 100%;
  font-size: 15px;

  th {
    line-height: 36px;
    font-weight: 600;
    padding-left: 15px;
  }

  td {
    line-height: 30px;
  }

  .numTitle {
    width: 60px;
  }

  .contentTitle {
    width: 318px;
    padding-left: 5px;
    text-align: left;
    cursor: pointer;
  }

  .contentTitle:hover, .music:hover {
    color: #1482d9;
  }

  .music {
    width: 60px;
    cursor: pointer;
  }
}

.txt_zhus {
  text-align: left;
  margin-top: 15px;
}
</style>
